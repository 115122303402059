import { Tooltip } from 'antd';
import { cleanText } from 'constants/helpers';
import { debounce } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { EditOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

interface EditableBoxProps {
  id?: string;
  tooltip?: string;
  disabled?: boolean;
  text?: string;
  className?: string;
  placeholder?: string;
  isTextarea?: boolean;
  onChanged: (txt: string) => any;
  onBlur?: (txt: string) => void;
  maxChars?: number;
  maxRows?: number;
}
const EditableBox: FC<EditableBoxProps> = ({
  text = '',
  tooltip,
  id = '',
  onChanged,
  onBlur,
  disabled = true,
  className = '',
  placeholder = '',
  isTextarea = false,
  maxChars = 0,
  maxRows = undefined
}) => {
  const input = useRef(undefined);
  const textValue = useRef<HTMLTextAreaElement>();
  useEffect(() => {
    textValue.current.value = text
  }, [text])
  const _onChange = (evt) => {
    //console.log('🚀 ~ _onChange', evt);

    //console.log(input.current);
    _changeValue(evt.target.value)
  }

  const preventDiv = (ev) => {
    //return key
    if (ev.keyCode === 13) {
      if (!isTextarea) {
        ev.currentTarget.blur();
      } else {
        ev.preventDefault();
        _changeValue(textValue.current + ' ')

      }
    }
  };

  const _onBlur = (evt) => {
    //console.log('🚀 ~ _onBlur', evt);
    if (onBlur) {
      setTimeout(() => onBlur(textValue.current.value), 500);
    }
  };

  const _onPaste = (event: any) => {
    const currentValue = event.target.value;
    console.log("🚀 ~ currentValue", currentValue)

    _changeValue(currentValue)
  };

  const _changeValue = (value) => {

    textValue.current.value = cleanText(value)
    onChanged(textValue.current.value);
  }



  return (
    <TextareaAutosize
      minRows={1}
      maxRows={maxRows}
      ref={textValue}
      id={id}
      placeholder={placeholder}
      className={classnames('editable', className, { disabled })}
      // value={cleanText(textValue?.current.value)}
      onKeyDown={preventDiv}
      data-placeholder={placeholder || tooltip}
      disabled={disabled} // use true to disable edition
      onChange={_onChange} // handle innerHTML change
      onBlur={_onBlur}
      onPaste={_onPaste}
    />
  );


};
export default EditableBox;
